import { Select } from "antd";
import React from "react";
import { Icon } from "src/components/atoms/icons";
const { Option } = Select;

interface Props {
  columnOption: string[];
  sendFilteredColumns: (data: any) => void;
  values: any;
  setFiltersData: any;
  isShow: boolean;
}
export default function Filters({
  columnOption,
  sendFilteredColumns,
  values,
  setFiltersData,
  isShow,
}: Readonly<Props>) {
  const serviceFilterArray = [
    {
      value: "lcl",
      label: <div className="  text-12 font-medium leading-18 ">LCL</div>,
    },
    {
      value: "fcl",
      label: <div className="text-12 font-medium leading-18 ">FCL</div>,
    },
    {
      value: "air",
      label: <div className="  text-12 font-medium leading-18 ">Air</div>,
    },
    {
      value: "road",
      label: <div className="  text-12 font-medium leading-18 ">Road</div>,
    },
    {
      value: "rail",
      label: <div className="  text-12 font-medium leading-18 ">Rail</div>,
    },
  ];
  const defaultServiceSelectedValues = serviceFilterArray;

  const handleStatusChange = (value: string) => {
    setFiltersData((prev: any) => ({
      ...prev,
      status: value || null,
    }));
  };

  const handleServiceChange = (value: any) => {
    setFiltersData((prev: any) => ({
      ...prev,
      service_type: value.join(",") || null,
    }));
  };

  return (
    <>
      <div
        className={
          !isShow
            ? " mt-5 flex w-full items-start justify-end space-x-4 pb-5"
            : "hidden"
        }
      >
        <div className=" w-auto md:w-full ">
          <Select
            mode="multiple"
            className="multiSelect select-status rounded-md border-2 bg-white  py-1"
            placeholder="Service Type"
            style={{ width: "100%", minWidth: "150px" }}
            maxTagCount="responsive"
            onChange={handleServiceChange}
            defaultValue={defaultServiceSelectedValues}
            // value={values.service? values.service.split(","): []}
            options={serviceFilterArray}
          />
        </div>
        <div className="w-full rounded-lg  ">
          <Select
            placeholder="Status"
            style={{ width: "100%", minWidth: "110px" }}
            className="select-status  w-full border-2 bg-white  "
            onChange={handleStatusChange}
            value={values.status}
            options={[
              {
                value: "requested",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl bg-yellow-100 px-8 py-2 text-12 font-medium leading-18 text-yellow-700">
                      Requested
                    </div>
                  </div>
                ),
              },
              {
                value: "booked",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl  bg-success-50 px-8 py-2 text-12 font-medium leading-18 text-success-700">
                      Booked
                    </div>
                  </div>
                ),
              },
              {
                value: "pending",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl bg-purple-50 px-8 py-2 text-12 font-medium leading-18 text-purple-700">
                      Pending
                    </div>
                  </div>
                ),
              },
              {
                value: "failed",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl bg-error-danger-50 px-8 py-2 text-12 font-medium leading-18 text-error-danger-700">
                      Failed
                    </div>
                  </div>
                ),
              },
              {
                value: "complete",
                label: (
                  <div className="flex justify-center">
                    <div className="rounded-2xl bg-sky-50 px-8 py-2 text-12 font-medium leading-18 text-blue-800">
                      Complete
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <div
        className={
          isShow
            ? "mt-5 flex w-full items-start justify-end space-x-2 pb-5"
            : "hidden"
        }
      >
        <div className="">
          <div className="w-auto md:w-full ">
            <Select
              mode="multiple"
              className="multiSelect select-status rounded-md border-2 bg-white  py-1"
              placeholder="Show"
              defaultValue={columnOption}
              style={{ width: "100%", minWidth: "135px" }}
              maxTagCount="responsive"
              onChange={sendFilteredColumns}
              suffixIcon={<Icon.McsIcEye />}
            >
              {columnOption.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </>
  );
}
